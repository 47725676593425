<template>
  <div>
    <el-form :inline="true" :model="queryForm" size="small">
      <el-form-item label="受访人">
        <el-select v-model="queryForm.target_member_id" filterable remote clearable :remote-method="getRecordData"
          :loading="recordLoading" placeholder="输入受访人名字进行搜索">
          <el-option v-for="item in recordData" :key="item.id" :label="item.realname" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="访问日期">
        <el-date-picker v-model="date_range" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleSearch" type="primary">查询</el-button>
        <el-button @click="handleAdd" type="primary">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData" v-loading="tableLoading" border stripe
      :header-cell-style="{background:'#E4E7ED',color: '#606266'}" size="small" max-height="500px" style="width: 100%;">
      <el-table-column label="#" type="index" width="60"></el-table-column>
      <el-table-column prop="target.realname" label="受访人"></el-table-column>
      <el-table-column prop="visitor_name" label="访问人"></el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
      <el-table-column prop="visit_time" label="来访时间"></el-table-column>
    </el-table>
    <div class="margin-t-10 flex">
      <el-pagination background layout="prev, pager, next, total, jumper" :total="total"
        :current-page.sync="queryForm.page" :page-size="pageSize" @current-change="getVisitData">
      </el-pagination>
      <el-button size="small">确定</el-button>
    </div>

    <!-- 新增、编辑的弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" @close="closeDialog" width="400px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="受访人" prop="target_member_id">
          <el-select v-model="form.target_member_id" filterable remote clearable :remote-method="getRecordData"
            :loading="recordLoading" placeholder="输入受访人名字进行搜索" class="el-input_inner--rewrite">
            <el-option v-for="item in recordData" :key="item.id" :label="item.realname" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="访问人" prop="visitor_name">
          <el-input v-model="form.visitor_name"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="来访时间" prop="visit_time">
          <el-date-picker v-model="form.visit_time" type="datetime" placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss" class="el-input_inner--rewrite">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button :loading="submitting" @click="handleSubmit" type="primary">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    saveVisitAPI,
    getVisitDataAPI,
  } from '@/api/visit.js'
  import {
    getUserRecordDataAPI
  } from '@/api/user-record/record.js'

  import {
    formatDate
  } from '@/utils/common-fun.js'
  export default {
    name: 'Visit',
    data() {
      return {
        recordData: [],
        recordLoading: false,

        queryForm: {
          page: 1,
          target_member_id: '',
          start: '',
          end: '',
        },

        // 表格相关
        tableData: [],
        total: 0,
        pageSize: 0,
        tableLoading: false,

        // 表单相关
        showDialog: false,
        dialogTitle: '',
        submitting: false,
        form: {
          target_member_id: '',
          visitor_name: '',
          remark: '',
          visit_time: ''
        },
        rules: {
          target_member_id: [{
            required: true,
            message: '受访者不能为空',
            trigger: 'change'
          }],
          visitor_name: [{
            required: true,
            message: '访问人不能为空',
            trigger: 'blur'
          }],
          remark: [{
            required: true,
            message: '备注不能为空',
            trigger: 'blur'
          }],
          visit_time: [{
            required: true,
            message: '来访时间不能为空',
            trigger: 'change'
          }],
        },
        // 查询时间
        date_range: [],
      }
    },
    created() {
      let date = formatDate(new Date()).split(' ')[0]
      this.date_range = [date + ' 00:00:00', date + ' 23:59:59'];
      this.queryForm.start = date + ' 00:00:00';
      this.queryForm.end = date + ' 23:59:59';
      this.getVisitData()
      this.getRecordData()
    },
    methods: {
      // 新增按钮
      handleAdd() {
        this.dialogTitle = '新增'
        this.showDialog = true
      },
      // 查询按钮
      handleSearch() {
        this.queryForm.page = 1
        this.queryForm.start = this.date_range[0]
        this.queryForm.end = this.date_range[1]
        console.log(this.date_range)
        this.getVisitData()
      },
      closeDialog() {
        this.$refs.form.resetFields()
        this.showDialog = false
      },
      // 提交按钮
      handleSubmit() {
        this.$refs.form.validate(isValid => {
          if (!isValid) return

          this.submitting = true
          const copyForm = JSON.parse(JSON.stringify(this.form))
          // 提交
          saveVisitAPI(copyForm).then(res => {
            this.$message.success(res.msg)
            this.closeDialog()
            this.getVisitData()
          }).finally(() => this.submitting = false)
        })
      },
      // 获取来访数据
      getVisitData() {
        this.tableLoading = true

        getVisitDataAPI(this.queryForm).then(res => {
          this.tableData = res.data
          this.total = res.total
          this.pageSize = res.per_page
          this.tableLoading = false
        })
      },
      // 获取用户档案数据
      getRecordData(keyword = '') {
        this.recordLoading = true

        setTimeout(() => {
          getUserRecordDataAPI({
            keyword,
            page: 1
          }).then(res => {
            this.recordData = res.data
            this.recordLoading = false
          })
        }, 300)
      }
    }
  }
</script>

<style scoped>
</style>
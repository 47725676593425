// 来访管理
import request from '@/utils/util.js'

// 新增、编辑
export function saveVisitAPI(data) {
	return request.post('/visit/save', data)
}

// 列表
export function getVisitDataAPI(data) {
	return request.get('/visit/list', data)
}
